<template>
  <v-dialog persistent v-model="visible">
    <ValidationObserver ref="form">
      <form>
        <v-card v-if="!isSubmitted">
          <v-card-title
            class="justify-center text-subtitle-1 font-weight-6 pb-3"
          >
            Registration
          </v-card-title>
          <v-card-text class="text-center pb-3">
            <v-row>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.email"
                    class="register-form p-0 m-0"
                    placeholder="eg. unicorn@eatmol.com"
                    label="Email"
                    color="primary"
                    autocomplete="email"
                    dense
                    outlined
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Contact No"
                  :rules="{ required: true, regex: /^\d{10,13}$/ }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.contactNo"
                    class="register-form p-0 m-0"
                    placeholder="0123366899"
                    label="Contact No"
                    color="primary"
                    autocomplete="contact-no"
                    dense
                    outlined
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="First Name"
                  :rules="{ required: true, min: 3, max: 30 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.firstName"
                    class="register-form p-0 m-0"
                    placeholder="eg. Gordon"
                    label="First Name"
                    color="primary"
                    autocomplete="first-name"
                    dense
                    outlined
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Last Name"
                  :rules="{ required: true, min: 3, max: 30 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.lastName"
                    class="register-form p-0 m-0"
                    placeholder="eg. Ramsay"
                    label="Last Name"
                    color="primary"
                    autocomplete="last-name"
                    dense
                    outlined
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  vid="password"
                  name="Password"
                  :rules="{ required: true, min: 8 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.password"
                    class="register-form p-0 m-0"
                    placeholder="Strong password secure your account"
                    label="Password"
                    color="primary"
                    autocomplete="off"
										:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
										:type="show ? 'text' : 'password'"
                    :error-messages="errors"
                    :success="valid"
                    dense
                    outlined
                    hide-details
                    @click:append="show = !show"
                  >
                  </v-text-field>
                  <ul class="text-caption text-left error--text mt-2" v-if="errors.length > 0">
                    <li>Min 8 characters</li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Confirm"
                  :rules="{ required: true, confirmed: 'password' }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="registerForm.confirmation"
                    class="register-form p-0 m-0"
                    placeholder="Confirm your password"
                    label="Confirm"
                    color="primary"
                    autocomplete="off"
										:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
										:type="show ? 'text' : 'password'"
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                    dense
                    outlined
                    @click:append="show = !show"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" class="mt-1 pr-2 pl-5">
                <v-btn
                  :disabled="isLoading"
                  color="grey lighten-3"
                  class="text-caption"
                  block
                  @click="$emit('close')"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="6" class="mt-1 pl-2 pr-5">
                <v-btn
                  :disabled="isLoading"
                  color="primary"
                  class="text-caption font-weight-6"
                  block
                  @click="register()"
                  >Register</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title class="text-h5"> </v-card-title>
          <v-card-text class="px-5 text-body-2 text-justify font-weight-5">
            Welcome {{ registerForm.firstName + ' ' + registerForm.lastName }}, you may login to your new signup account now.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-body-2 text-capitalize font-weight-6 mr-1 mb-1"
              small
              text
              outlined
              @click="$emit('close')"
            >
              Okay !
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
    <v-alert
      v-if="error"
      class="alert-global text-body-2 white--text p-2"
      type="error"
      transition="fade-transition"
      dense
      >{{ error }}</v-alert
    >
  </v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'RegisterModal',
  props: ['visible'],
  components: {
    ValidationObserver, 
    ValidationProvider,
  },
	data() {
		return {
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      customer: null,
      registerForm: {
        email: '',
        contactNo: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmation: '',
        merchantId: 0,
      },
      isSubmitted: false,
      isLoading: false,
      show: false,
      error: null,
		}
	},
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
  },
  methods: {
    ...mapActions('customer', ['create']),
    async register() {
      this.isLoading = true;

      if ((await this.$refs.form.validate())) {
        try {
          this.$gtag.event('sign_up', {
            'event_category': 'engagement',
            'event_label': 'Register',
            'event_value': `Email: ${this.registerForm.email}, Contact No: ${this.registerForm.contactNo}`, 
          });

          this.customer = await this.create(this.registerForm);
          this.isSubmitted = true;
          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
          this.error = e.message;
          setTimeout(() => this.error = null, 5000);
        }
      } else {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.registerForm.merchantId = this.profile.merchant_id;
  },
}
</script>
<style>
.theme--light.v-input.register-form input {
  font-size: 12px !important;
}
.theme--light.v-input.register-form input::placeholder {
  font-size: 12px !important;
}
.theme--light.v-input.register-form .v-text-field__prefix {
  font-size: 12px !important;
}
.alert-global {
  position: fixed;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(90vw - 48px);
}

@media screen and (min-width: 1024px) {
  .alert-global {
    width: 326px;
  }
}
</style>