<template>
  <v-btn
    fab
    color="#CF4332"
    class="text-capitalize ml-2"
    dark
    small
    depressed
    @click="googleLogin()"
  >
    <v-icon
      class="mx-2"
      style="font-size: 22px"
      aria-hidden="true"
    >mdi-google</v-icon>
  </v-btn>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import queryString from 'query-string';

export default {
  name: 'Googlelogin',
  props: ['params'], 
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('auth', ['authenticate']),
    getGoogleAuthURL() {
      const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
      const options = {
        redirect_uri: `${process.env.VUE_APP_API_HOST}/auth/google`,
        client_id: this.params.client_id,
        access_type: 'offline',
        state: this.profile.sessionToken,
        response_type: 'code',
        prompt: 'consent',
        scope: [
          'https://www.googleapis.com/auth/userinfo.profile',
          'https://www.googleapis.com/auth/userinfo.email',
        ].join(' '),
      };

      return `${rootUrl}?${queryString.stringify(options, true)}`;
    },
    googleLogin() {
      this.$gtag.event('login', {
        'event_category': 'engagement',
        'event_label': 'Login',
        'event_value': 'google',
      });

      window.location = this.getGoogleAuthURL();
    }
  },
  async mounted() {

  },
};
</script>
<style>
.button {
  color: white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>
