<template>
  <v-btn
    fab
    color="#3C66C4"
    class="text-capitalize"
    dark
    small
    depressed
    v-if="ready"
    @click="logInWithFacebook()"
  >
    <v-icon
      class="mx-2"
      style="font-size: 22px"
      aria-hidden="true"
    >mdi-facebook</v-icon>
  </v-btn>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'facebookLogin',
  data: function() {
    return {
      loggedIn: false,
      ready: false,
    };
  },
  computed: {
    ...mapState('config', {
      fbAppId: state => state.facebook,
    }),
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('auth', ['authenticate']),
    async logInWithFacebook() {
      window.FB.login(response => {
        if (response.authResponse) {
          window.FB.api(
            `/${response.authResponse.userID}/`,
            'GET',
            { fields: 'id,name,email,picture.type(large)' },
            async res => {
              try {
                this.$gtag.event('login', {
                  'event_category': 'engagement',
                  'event_label': 'Login',
                  'event_value': 'facebook',
                });

                await this.authenticate({
                  email: res.email,
                  firstName: res.name,
                  lastName: '',
                  avatar: res.picture?.data?.url || '',
                  method: 'facebook',
                });

                this.$emit('success');
              } catch (e) {
                console.log('error', e);
                this.error = 'Login Facebook failed !';

                setTimeout(() => (this.error = null), 5000);
              }
            }
          );

          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
        } else {
          alert('User cancelled login or did not fully authorize.');
        }
      });
      return false;
    },
    async initFacebook() {
      window.FB.init({
        appId: this.fbAppId, // You will need to change this
        cookie: true, // This is important, it's not enabled by default
        version: 'v10.0',
      });

      return true;
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
  },
  async mounted() {
    await this.getAdminOption({ key: 'facebook' });
    await this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
    setTimeout(() => (this.ready = this.initFacebook()), 300);
  },
};
</script>
<style>
.button {
  color: white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>
